import React, {useEffect, useState} from "react";
import {Button, Container, Grid} from "semantic-ui-react";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import Position from "../../framework/types/Position";
import NameWithDate from "../../framework/widgets/NameWithText/NameWithDate";
import RSATable from "../../framework/widgets/TableWithBackgrounds/RSATable";
import {lstAuditParticipantsReq, RoadSafetyAuditProps} from "../auditor/profile/DataModal";
import PromiseToast from "../../framework/components/Toast/PromiseToast";
import {
    AddMetoAudit,
    AddNewAudittoDB,
    fetchAuditorsByName,
    fetchSingleAudit
} from "../auditor/profile/ProfileController";
import {fetchAudits,fetchAllActiveAudits} from "./AuditController"
import DropDownOptions from "../../framework/types/DropDownOptions";
import {Oval} from "react-loader-spinner";
import TableHeader from "../../framework/types/TableHeader";
import moment from "moment/moment";
import {
    ValidationRequestProps,
    ValidationResponseProps,
    ValidationRuleProps
} from "../../framework/types/ValidationProps";
import Validations from "../../framework/common/Validations";
import ValidationRuleType from "../../framework/types/ValidationRuleType";
import {getSessionCookie} from "../../framework/common/SessionHandler";
import tags from "../../models/common/tag";
import {toast} from "react-toastify";
import ErrorToast from "../../framework/components/Toast/ErrorToast";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import {PaginationProps} from "antd";
import IconTextOutlineButton from "../../framework/components/Buttons/IconTextOutlineButton";
import {HashLink} from "react-router-hash-link";
import Modal from "../../framework/components/Modals";
import AddNewAudit from "../auditor/profile/AddNew Audit";
import DateRangeBox from "../../framework/components/TextBoxes/DateRangeBox";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import { AuditSearchReq } from "../../models/apiRequest/Audit/AuditSearchReq";


interface Props {
    //eslint-disable-next-line
    states: any;
    //eslint-disable-next-line
    auditStages: any;
    //eslint-disable-next-line
    toggleModel: any;
    //eslint-disable-next-line
    auditId: any;
    //eslint-disable-next-line
    isEdit: any;
    //eslint-disable-next-line
    auditorId: any;
    //eslint-disable-next-line
    setAuditor: any;
}

const SearchAudit = ({states, auditStages, toggleModel,auditId,isEdit,auditorId}: Props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [state, setState] = React.useState<RoadSafetyAuditProps>()
    const [rbtVal, setRbtVal] = useState('no')
    const [chkTfNSW, setChkTfNSW] = useState(false)
    const [addNewAudit, setAddNewAudit] = React.useState<boolean>(true);
    const [intLoad, setIntLoad] = useState(true);
    const [stateList, setStateList] = useState<DropDownOptions[]>([]);
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [isEditCourse, setIsEditCourse] = React.useState<boolean>(false);

    const [auditDateError, setAuditDateError] = React.useState('')
    //eslint-disable-next-line
    const [auditTypeError, setAuditTypeError] = React.useState('')
    const [auditLocationError, setAuditLocationError] = React.useState('')
    const [titleError, setTitleError] = React.useState('')
    const [projectDescError, setProjectDescError] = React.useState('')
    const [purposeOfAuditError, setPurposeOfAuditError] = React.useState('')
    const [clientCompanyError, setClientCompanyError] = React.useState('')
    const [clientContactPersonError, setClientContactPersonError] = React.useState('')
    const [clientContactNumberError, setClientContactNumberError] = React.useState('')
    const [clientMobileNumberError, setClientMobileNumberError] = React.useState('')
    const [clientContactEmailError, setClientContactEmailError] = React.useState('')
    const [isAddMember, setIsAddmember] = React.useState(false)
    const [txtAddMember, setTxtAddMember] = React.useState('');

    const [pages, setPages] = useState(1)
    const [type, setType] = useState('active')
    const [current, setCurrent] = useState(1);
    const [dateRange, setDateRange] = useState('')
    const pathName = window.location.pathname;

    useEffect(() => {
        setState({...state, auditorId:auditorId})
        if(auditId > 0){
            //setAddNewAudit(false);
            setLoading(true);
            Promise.resolve(fetchSingleAudit(auditId)).then((res) => {
                setState({...res,finalSignoffDate:moment(res.finalSignoffDate).format("DD-MM-YYYY")});
                setRbtVal(res.isTfNSW ? 'yes' : 'no');
                setIntLoad(false)
                setLoading(false);
                setIsEditCourse(true)
                if(res?.lstAuditParticipantsReq != undefined && res?.lstAuditParticipantsReq?.length > 0){
                    const objAddAuditors:object[] = []//rsaAddAuditorDet
                    res?.lstAuditParticipantsReq.map((r)=>{
                        const row = {
                            auditorId:r.auditorId,
                            name:r.auditorFullName,
                            status:r.auditorId == 0 ? 'Unregistered Auditor' : 'Auditor',
                            isTeamLeader:r.isLeadAuditor,
                            isConfirmedByAuditor:r.isConfirmed
                        }
                        objAddAuditors.push(assignAddAuditortoAuditRaw(row,true));
                    })
                    setRSAAddAuditorDet(objAddAuditors);
                }
            });

        }
    },[auditId]);

    // useEffect(()=>{
    //     if(pathName == '/audits'){
    //         fetchAllAuditsFromDb('active',0);
    //     }
    // },[])

    useEffect(() => {
        Promise.resolve(states).then((value) => {
            setStateList(value)
        })
    }, [states])

    useEffect(() => {
        Promise.resolve(auditStages).then((value) => {
            setStages(value)
            setIsEditCourse(!isEdit)
        })
    }, [auditStages])

    const rsaAuditorHead: TableHeader[] = [
        {
            text: ""
        },
        {
            text: "Audit ID"
        },
        {
            text: "Sign off date"
        },
        {
            text: "Name"
        },
        {
            text: "Location"
        },
        /*{
            text: "Is TfNSW"
        },
        {
            text: "Confirmed"
        },*/
        {
            text: " "
        }
    ];
    const rsaAddAuditorHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "Name"
        },
        {
            text: "Auditor's Status"
        },
        {
            text: "Lead Auditor"
        },
        {
            text: "Confirmed"
        },
        {
            text: " "
        }
    ];
    const rsaAddAuditorListHead: TableHeader[] = [
        {
            text: "Auditor ID"
        },
        {
            text: "First Name"
        },
        {
            text: "Last Name"
        },
        {
            text: " "
        }
    ];
    //eslint-disable-next-line
    const rsaAuditDetRow: object[] = [
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "No Audit Found",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        },
        {
            tag: "text",
            text: "",
            icon: "",
            onclick: () => {
                alert("test")
            }
        }
    ];
    const session = getSessionCookie();

    const makeMeLeader = (id:string, name:string, isLeader:boolean)=>{
        //eslint-disable-next-line
        const data = rsaAddAuditorDet
        const allData :object[] = []
        data.forEach(x => {
            if(Array.isArray(x)){
               if(x[0].text == id && x[1].text == name){
                   x[3].text =  !x[3].text
               }else{
                   x[3].text = false
               }
                allData.push(x)
            }
        })
        setRSAAddAuditorDet(allData)

        const allStateData:lstAuditParticipantsReq[] = []
        const dataState = state?.lstAuditParticipantsReq ? state?.lstAuditParticipantsReq : []
        dataState.forEach((x)=>{
            if(x.auditorId == parseInt(id) && x.auditorFullName == name){
                x.isLeadAuditor = !x.isLeadAuditor
            }else{
                x.isLeadAuditor = false
            }
            allStateData.push(x)
        })
        setState({...state, lstAuditParticipantsReq : allStateData})
    }
    const assignAddAuditortoAuditRaw = (raw: any,edit:boolean) => {
        return [
            {
                tag: "text",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.name,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.status,
                icon: "",
                onclick:null
            },
            !edit? {
                tag: "checkbox",
                text: raw.isTeamLeader,
                icon: "",
                onclick:()=>{makeMeLeader(raw.auditorId,raw.name,raw.isTeamLeader)}
            } : {
                tag: tags.icon,
                text: "",
                icon: raw.isTeamLeader == true ? "check" : "times",
                onclick:null
            } ,
            {
                tag: tags.icon,
                text: "",
                icon: raw.isConfirmedByAuditor == true ? "check" : "times",
                onclick:null
            }
        ]
    }
    const [rsaAuditDet, setRSADet] = useState<object[]>([])
    const [rsaAuditorDet, setRSAAuditorDet] = useState<object[]>([])
    const [rsaAddAuditorDet, setRSAAddAuditorDet] = useState<object[]>([assignAddAuditortoAuditRaw({
        auditorId:session.auditorId,
        name:session.userDisplayName,
        status:'Auditor',
        isTeamLeader:true,
        isConfirmedByAuditor:false
    },false)])
    //eslint-disable-next-line
    let failedResult: ValidationResponseProps[] = []


    const Validating = () => {
        setAuditDateError('')
        setAuditLocationError('')
        setTitleError('')
        setProjectDescError('')
        setPurposeOfAuditError('')
        setClientCompanyError('')
        setClientContactPersonError('')
        setClientContactNumberError('')
        setClientMobileNumberError('')
        setClientContactEmailError('')
        setAuditTypeError('')
        let result = true;
        let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;
        //course section
        if (state != undefined) {
          //Audit Date Validation
            if (state.finalSignoffDate != undefined ) {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const date1=  moment(state.finalSignoffDate,'DD/MM/YYYY').format('YYYY-MM-DD')
                rule = [{
                    Rule: `${new Date(date1) < new Date(Date.now())}`,
                    Type: ValidationRuleType.CUSTOM,
                }]
                ValidateProps = {
                    value: state.finalSignoffDate,
                    fieldType: 'date',
                    fieldName: 'Final Signoff Date',
                    required: true,
                    rules:rule,
                    fieldId: 'finalSignoffDate'
                } as ValidationRequestProps
                const finalSignoffDateVal: ValidationResponseProps = Validations(ValidateProps)
                if (!finalSignoffDateVal.Valid) {
                    result = false;
                    failedResult.push(finalSignoffDateVal)
                    setAuditDateError(finalSignoffDateVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Final Signoff Date is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditDateError(res.Msg)
            }

            //Audit Location Validation
            if (state.locationOfAudit != undefined ) {

                ValidateProps = {
                    value: state.locationOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Location',
                    required: true,
                    fieldId: 'locationOfAudit'
                } as ValidationRequestProps
                const locationOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!locationOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(locationOfAuditVal)
                    setAuditLocationError(locationOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Location is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setAuditLocationError(res.Msg)
            }

            //Audit Title Validation
            if (state.titleOfAudit != undefined ) {

                ValidateProps = {
                    value: state.titleOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Title',
                    required: true,
                    fieldId: 'titleOfAudit'
                } as ValidationRequestProps
                const titleOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!titleOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(titleOfAuditVal)
                    setTitleError(titleOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Title is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setTitleError(res.Msg)
            }

            //Audit Desc Validation
            if (state.projectDescription != undefined ) {

                ValidateProps = {
                    value: state.projectDescription,
                    fieldType: 'text',
                    fieldName: 'Audit Description',
                    required: true,
                    fieldId: 'projectDescription'
                } as ValidationRequestProps
                const projectDescriptionVal: ValidationResponseProps = Validations(ValidateProps)
                if (!projectDescriptionVal.Valid) {
                    result = false;
                    failedResult.push(projectDescriptionVal)
                    setProjectDescError(projectDescriptionVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Description is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setProjectDescError(res.Msg)
            }

            //Audit Purpose Validation
            if (state.purposeOfAudit != undefined ) {

                ValidateProps = {
                    value: state.purposeOfAudit,
                    fieldType: 'text',
                    fieldName: 'Audit Purpose',
                    required: true,
                    fieldId: 'purposeOfAudit'
                } as ValidationRequestProps
                const purposeOfAuditVal: ValidationResponseProps = Validations(ValidateProps)
                if (!purposeOfAuditVal.Valid) {
                    result = false;
                    failedResult.push(purposeOfAuditVal)
                    setPurposeOfAuditError(purposeOfAuditVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Audit Purpose is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setPurposeOfAuditError(res.Msg)
            }

            //Client Company Validation
            if (state.clientCompany != undefined ) {

                ValidateProps = {
                    value: state.clientCompany,
                    fieldType: 'text',
                    fieldName: 'Client Company',
                    required: true,
                    fieldId: 'clientCompany'
                } as ValidationRequestProps
                const clientCompanyVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientCompanyVal.Valid) {
                    result = false;
                    failedResult.push(clientCompanyVal)
                    setClientCompanyError(clientCompanyVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Company is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientCompanyError(res.Msg)
            }

            //Client Contact Person Validation
            if (state.clientContactPerson != undefined ) {

                ValidateProps = {
                    value: state.clientContactPerson,
                    fieldType: 'text',
                    fieldName: 'Client Contact Person',
                    required: true,
                    fieldId: 'clientContactPerson'
                } as ValidationRequestProps
                const clientContactPersonVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactPersonVal.Valid) {
                    result = false;
                    failedResult.push(clientContactPersonVal)
                    setClientContactPersonError(clientContactPersonVal.Msg)
                }
            } else {
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Person is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactPersonError(res.Msg)
            }

            //Client Contact Tel. Validation
            if (state.clientContactNumber != undefined && state.clientContactNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Contact Telephone',
                    required: true,
                    rules:rule,
                    fieldId: 'clientContactNumber'
                } as ValidationRequestProps
                const clientContactTelVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactTelVal.Valid) {
                    result = false;
                    failedResult.push(clientContactTelVal)
                    setClientContactNumberError(clientContactTelVal.Msg)
                }
            }else{
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Telephone is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactNumberError(res.Msg)
            }

            //Client Contact Mobile Validation
            if (state.clientMobileNumber != undefined && state.clientMobileNumber != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                const regx = "^\\({0,1}((0|\\+61|61)(\\ |-){0,1}(2|4|3|7|8)){0,1}\\){0,1}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{2}(\\ |-){0,1}[0-9]{1}(\\ |-){0,1}[0-9]{3}$"
                rule = [{
                    Rule: regx,
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientMobileNumber,
                    fieldType: 'phone',
                    fieldName: 'Client Mobile No',
                    required: true,
                    rules:rule,
                    fieldId: 'clientMobileNumber'
                } as ValidationRequestProps
                const clientContactMobileVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactMobileVal.Valid) {
                    result = false;
                    failedResult.push(clientContactMobileVal)
                    setClientMobileNumberError(clientContactMobileVal.Msg)
                }
            }

            //Client Contact Email Validation
            if (state.clientContactEmail != undefined && state.clientContactEmail != '') {
                let rule: ValidationRuleProps[] = {} as ValidationRuleProps[]
                rule = [{
                    Rule: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
                    Type: ValidationRuleType.REGEX,
                }]
                ValidateProps = {
                    value: state.clientContactEmail,
                    fieldType: 'email',
                    fieldName: 'Client Contact Email',
                    required: true,
                    rules:rule,
                    fieldId: 'clientContactEmail'
                } as ValidationRequestProps
                const clientContactEmailVal: ValidationResponseProps = Validations(ValidateProps)
                if (!clientContactEmailVal.Valid) {
                    result = false;
                    failedResult.push(clientContactEmailVal)
                    setClientContactEmailError(clientContactEmailVal.Msg)
                }
            }else{
                const res: ValidationResponseProps = {
                    Valid: false,
                    Msg: 'Client Contact Email is Required !'
                } as ValidationResponseProps
                failedResult.push(res)
                result = false;
                setClientContactEmailError(res.Msg)
            }

        } else {
            const res: ValidationResponseProps = {
                Valid: false,
                Msg: 'Please fill required fields in course details!'
            } as ValidationResponseProps
            failedResult.push(res)
            result = false;
            setAuditDateError('Course Date is Required !');
            setClientContactEmailError('Course is Required !');
        }

        return result
    }

    const Save = () => {
        //eslint-disable-next-line
        let allData :lstAuditParticipantsReq[] = []
        if(rsaAddAuditorDet.length > 0){
            const dataState = rsaAddAuditorDet
            dataState.forEach(x => {
                if(Array.isArray(x)){
                    const y:lstAuditParticipantsReq = {
                        auditorFullName: x[1].text,
                        auditorId: x[0].text > 0 ? x[0].text : 999999999,
                        isConfirmed:false,
                        isLeadAuditor:x[3].text,
                    }
                    allData.push(y)
                }
            })
            setState({...state, lstAuditParticipantsReq : allData})
        }
        if (!Validating()) {
            console.log(failedResult)
            //setErrorPop(true)
            return;
        }
        if(rbtVal === 'yes' && chkTfNSW == false){
            ErrorToast("Please confirm is TfNSW !")
            return;
        }
        
        if (state !== undefined && addNewAudit) {
            const saveResult = AddNewAudittoDB({...state, lstAuditParticipantsReq : allData})
            //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
            toast.dismiss()
            const id = toast.loading('Pending..');
            Promise.resolve(saveResult).then((value) => {
                //console.log('Add Audit Save',saveResult)

                if(value.response != undefined && value.response.data.error){
                    //toast.dismiss(id)
                    toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});

                }else{
                    toast.update(id, {render: "Successfully Added !", type: "success", isLoading: false});
                    setTimeout(()=>{toast.dismiss(id)},1500)
                    toggleModel('save')
                }

            })
        } else if (state !== undefined) {
            toast.dismiss()
            toggleModel()
        }
    }

    //eslint-disable-next-line
    const assignRaw = (raw: any) => {
        const auditorAudit = raw.isAuditorAdded
        return [
            {
                tag: "popup-icon",
                text: raw.auditID,
                icon: "view",
                onclick: raw.auditID,
                href:`/audits?id=${ raw.auditID}`
            },
            {
                tag: "text",
                text: raw.auditID,
                icon: "",
                onclick: raw.auditID,
            },
            {
                tag: "text",
                text: raw.finalSignoffDate,
                icon: "",
                onclick: raw.auditID,
            },
            {
                tag: "text",
                text: raw.name,
                icon: "",
                onclick: null,
                width: "250px"
            },
            {
                tag: "text",
                text: raw.location,
                icon: "",
                onclick:null,
                width: "251"
            },
            /*{
                tag: "text",
                text:raw.tfnsw,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.isPubliclyVisible,
                icon: "",
                onclick:null
            },*/
            session.status != 4 && raw.isPubliclyVisible == 'Yes' && !auditorAudit ? {
                tag: "button",
                text: "Add Me",
                width: '100px',
                icon: "",
                onclick: () => {
                    const addMe =AddMetoAudit(auditorId,raw.auditID)
                    PromiseToast('Pending...', 'Successfully Added !', 'Error', addMe)
                    Promise.resolve(addMe).then(() => {
                            toggleModel('Addme')
                    })
                }
            } : ''
        ]
    }

    const assignAddAuditorRaw = (raw: any) => {
        return [
            {
                tag: "text",
                text: raw.auditorId,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.firstName,
                icon: "",
                onclick: null
            },
            {
                tag: "text",
                text: raw.lastName,
                icon: "",
                onclick:null
            },
            {
                tag: "button",
                text: "Select",
                icon: "",
                onclick: () => {
                    AddAuditorToAudit(raw.auditorId,`${raw.firstName} ${raw.lastName}`,"Auditor")
                }
            }
        ]
    }

    const fetchAuditorsList = (auditorName:string) => {
        if(auditorName.length > 4){
            const lstAuditors = fetchAuditorsByName(auditorName)
            Promise.resolve(lstAuditors).then((row)=>{
                const objAuditors:object[] = [];
                if(row){
                    console.log(row)
                    row.map((r: any) => {
                        objAuditors.push(assignAddAuditorRaw(r))
                         //[...rsaAuditDet, assignRaw(r)])
                    })
                    setRSAAuditorDet(objAuditors)
                }else{
                    setRSAAuditorDet([])
                }
            })
        }
    }

    const AddAuditorToAudit = (auditorId:number, auditorName:string, auditorStatus:string) => {
        const row = {
            auditorId:auditorId,
            name:auditorName,
            status:auditorStatus,
            isTeamLeader:false,
            isConfirmedByAuditor:false
        }

        //eslint-disable-next-line
        let objlstAuditParticipantsReq = state?.lstAuditParticipantsReq ? state?.lstAuditParticipantsReq : [{
            auditorId:state?.auditorId ? state.auditorId : session.auditorId,
            auditorFullName : session.userDisplayName,
            isLeadAuditor : true,
            isConfirmed : false
        }]
        const auditorAudit:lstAuditParticipantsReq = {
            auditorId:auditorId > 0 ? auditorId : 999999999,
            auditorFullName : auditorName,
            isLeadAuditor : false,
            isConfirmed : false
        }

        objlstAuditParticipantsReq.push(auditorAudit)
        setState({...state, lstAuditParticipantsReq : objlstAuditParticipantsReq})
        const objAddAuditors:object[] = rsaAddAuditorDet
        objAddAuditors.push(assignAddAuditortoAuditRaw(row, false));
        setRSAAddAuditorDet(objAddAuditors);
        setTxtAddMember('')
        setIsAddmember(!isAddMember)
        setRSAAuditorDet([])
    }

    const fetchAllAuditsFromDb = (typeCall:string,pageNumber:number) => {
        //console.log("fetching audits" , state)
            setRSADet([])
            setLoading(true)
            const audits = fetchAllActiveAudits(typeCall,pageNumber)
            Promise.resolve(audits).then((value) => {
                //console.log('All',value)
                setLoading(false)
                const objAudits:object[] = [];
                if (value.auditsArray.length > 0) {
                    setAddNewAudit(false)
                    //eslint-disable-next-line
                    value.auditsArray.map((r: any) => {
                        objAudits.push(assignRaw(r))
                        //setRSADet(objAudits) //[...rsaAuditDet, assignRaw(r)])
                    })
                    setPages(value.objTotalPage)
                    console.log('objAudit',objAudits)
                    setRSADet(objAudits)
                } else if(session.status == 4) {
                    setRSADet([rsaAuditDetRow])
                    setAddNewAudit(false)
                    setIntLoad(false)
                }else{
                    setRSADet([])
                    setAddNewAudit(true)
                    setIntLoad(false)
                }
            })

    }

    const onChange: PaginationProps['onChange'] = (page) => {
        setRSADet([])
        fetchAllAuditsFromDb(type,page - 1);
        setCurrent(page);
    };

    const fetchAuditsFromDb = (page?:number) => {
        const dates = dateRange.split('-')
        if(moment(dates[0],'DD/MM/YYYY').isAfter()) {
            ErrorToast("This date can't be in future !")
            setDateRange('')
            return;
        }
        if(moment(dates[1],'DD/MM/YYYY').isAfter()) {
            ErrorToast("This date can't be in future !")
            setDateRange('')
            return;
        }
        if(moment(dates[1],'DD/MM/YYYY').isBefore(moment(dates[0],'DD/MM/YYYY'))) {
            ErrorToast('End date must be later than the start date !')
            setDateRange('')
            return;
        }

        //console.log("fetching audits" , state)
        if (state && ((dates[0] && dates[0].length > 0) || 
           (state.stageOfAudit && state.stageOfAudit.length > 0) || 
           (state.stateAuditPerformed && state.stateAuditPerformed.length > 0) || 
           (state.titleOfAudit && state.titleOfAudit.length > 0) ||
           (state.locationOfAudit && state.locationOfAudit.length > 0) ||
           (state.auditId?.toString() && state.auditId?.toString().length > 0) || 
           (state.clientCompany && state.clientCompany.length > 0)
           )) {
            setLoading(true)
            const dataReq:AuditSearchReq = {
                signOffDate: moment(dates[0].trimEnd(),'DD/MM/YYYY').format('YYYY-MM-DD'), 
                signOffDate2: moment(dates[1],'DD/MM/YYYY').format('YYYY-MM-DD'), 
                stageID: parseInt(state.stageOfAudit ? state.stageOfAudit : '0'), 
                LocationStateID: parseInt(state.stateAuditPerformed ? state.stateAuditPerformed : '0'),
                auditTitle:state?.titleOfAudit,
                auditId:state?.auditId?.toString(),
                auditLocation: state?.locationOfAudit,
                clientCompany: state?.clientCompany,
                pageSize :10,
                pageNumStartingZero:page ? page : 0,
                orderByFieldsIncAscOrDesc :""
            }
            const audits = fetchAudits(dataReq)
            Promise.resolve(audits).then((value) => {
               // console.log(value)
                setLoading(false)
                const objAudits:object[] = []//rsaAuditDet;
                if (value.length > 0) {
                    setAddNewAudit(false)
                    //eslint-disable-next-line
                    value.map((r: any) => {
                        objAudits.push(assignRaw(r))
                        //setRSADet(objAudits) //[...rsaAuditDet, assignRaw(r)])
                    })
                    setPages(0)
                    console.log('objAudit',objAudits)
                    setRSADet(objAudits)
                } else if(session.status == 4) {
                    setRSADet([rsaAuditDetRow])
                    setAddNewAudit(false)
                    setIntLoad(false)
                    setPages(0)
                }else{
                    setRSADet([])
                    setAddNewAudit(true)
                    setIntLoad(false)
                    setPages(0)
                }
            })
        }

    }
    useEffect(()=>{
        //eslint-disable-next-line
        //debugger;
        //if(state?.auditId == undefined){
            fetchAuditsFromDb(0)
        //}

    },[state?.finalSignoffDate, state?.finalSignoffDate2, state?.stageOfAudit, state?.stateAuditPerformed, state?.titleOfAudit, state?.auditId, state?.locationOfAudit, state?.clientCompany])

    const toggleModelPop = (e: any) => {
        //eslint-disable-next-line
        //debugger;
        setOpen(!open)
    }

    return (
        <React.Fragment>
            <Container>
                <Grid>
                    { pathName == '/audits' ?(<Grid.Row columns={1}>
                        <Grid.Column width={12} computer={12} tablet={8} mobile={16}></Grid.Column>
                        <Grid.Column width={2} computer={2} tablet={16} mobile={16} className={'button-flex'}>
                            <IconTextOutlineButton id={'cmbAddAudits'} style={{width: '150px'}} icon='add'
                                                   onClick={(e: any) => {
                                                       toggleModelPop(e)
                                                   }} color='blue' className={"background-transaparent"} text={'Add Audits'}
                                                   disabled={session.status == 4}/>
                        </Grid.Column>
                        <Grid.Column width={2} computer={2} tablet={16} mobile={16} className={'button-flex'}>
                            <Button basic outline={1} style={{width: '150px'}} content='blue' id={'cmbmyAudits'}
                                    color='blue' className={"background-transaparent"}><HashLink
                                to={"/accreditation#audits"}>My Audits</HashLink></Button>
                        </Grid.Column>
                    </Grid.Row>):null}
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDefault
                                    labelText={'Audit ID'}
                                    fluid={true}
                                    labelPosition={Position.Top}
                                    id={'txtAuditId'}
                                    type={'text'}
                                    required={false}
                                    placeholder={'Enter Audit ID'}
                                    value={state?.auditId}
                                    onError={titleError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, auditId: e.target.value})
                                        setTitleError('')
                                    }}
                                    disabled={isEditCourse}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDefault
                                    labelText={'Title of Audit'}
                                    fluid={true}
                                    labelPosition={Position.Top}
                                    id={'txtEmail'}
                                    type={'text'}
                                    required={false}
                                    placeholder={'Enter Title of Audit'}
                                    value={state?.titleOfAudit}
                                    onError={titleError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, titleOfAudit: e.target.value})
                                        setTitleError('')
                                    }}
                                    disabled={isEditCourse}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDefault
                                    labelText={'Location of Audit'}
                                    fluid={true}
                                    labelPosition={Position.Top}
                                    id={'txtCertificateNumber'}
                                    type={'text'}
                                    required={false}
                                    placeholder={'Enter Location of Audit'}
                                    value={state?.locationOfAudit}
                                    onError={auditLocationError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, locationOfAudit: e.target.value})
                                        setAuditLocationError('')
                                    }}
                                    disabled={isEditCourse}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Stage of Audit'}
                                labelPosition={Position.Top}
                                id={'ddStageAudit'}
                                placeholder={'Enter Stage of Audit'}
                                options={stages}
                                required={false}
                                clearable={true}
                                value={state?.stageOfAudit}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, stageOfAudit: data.value});
                                }}
                                //onLostFocus={fetchAuditsFromDb}
                                disabled={isEditCourse}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'State Audit Performed in'}
                                labelPosition={Position.Top}
                                id={'ddStateAudit'}
                                placeholder={'Select State of Audit'}
                                options={stateList}
                                required={false}
                                clearable={true}
                                value={state?.stateAuditPerformed}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, stateAuditPerformed: data.value});
                                }}
                                disabled={isEditCourse}
                                //onLostFocus={fetchAuditsFromDb}
                            />
                        </Grid.Column>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <NameWithDefault
                                    labelText={"Client Company/Organization"}
                                    fluid={true}
                                    labelPosition={Position.Top}
                                    id={'txtCCO'}
                                    type={'text'}
                                    required={false}
                                    placeholder={'Enter Company/Organization'}
                                    value={state?.clientCompany}
                                    onError={clientCompanyError}
                                    //eslint-disable-next-line
                                    onChange={(e: any) => {
                                        e.preventDefault();
                                        setState({...state, clientCompany: e.target.value})
                                        setClientCompanyError('')
                                    }}
                                    disabled={isEditCourse}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>
                            <Grid.Column width={4} computer={4} tablet={8} mobile={16} largeScreen={4} widescreen={4} textAlign={"center"}>
                                <span style={{display:'inline-flex'}}>{'Final Sign off Date Range'}</span>
                            </Grid.Column>
                            <DateRangeBox
                                id={'txtFromDate'}
                                fluid={true}
                                placeholder={'From Date'}
                                onChange={(e:any,data:any) => {
                                    setDateRange(data.value)
                                    if(data.value.includes('-')){
                                        const dates = data.value.split('-')
                                        console.log(dates[0].trimEnd())
                                        if(moment(dates[0].trimEnd(),'DD/MM/YYYY').isAfter()){
                                            setAuditDateError('Final Sign off First Date Invalid !')
                                            setState({...state, finalSignoffDate: ''})
                                            return;
                                        }else {
                                            setAuditDateError('')
                                            setState({...state, finalSignoffDate: dates[0].trimEnd()})
                                        }

                                        if(moment(dates[1],'DD/MM/YYYY').isAfter()){
                                            setAuditDateError('Final Sign off Second Date Invalid !')
                                            setState({...state, finalSignoffDate2: ''})
                                            return;
                                        }else {
                                            setAuditDateError('')
                                            setState({...state, finalSignoffDate2: dates[1]})
                                        }
                                    }

                                }}
                                value={dateRange}
                            />
                            {/*<NameWithDate*/}
                            {/*    labelText={'Final Sign off Date'}*/}
                            {/*    fluid={true}*/}
                            {/*    labelPosition={Position.Top}*/}
                            {/*    id={'txtDate'} type={'date'}*/}
                            {/*    required={false}*/}
                            {/*    placeholder={'Enter Date'}*/}
                            {/*    value={state?.finalSignoffDate}//moment(state?.finalSignoffDate).format('DD/MM/YYYY') }*/}
                            {/*    onError={auditDateError}*/}
                            {/*    //eslint-disable-next-line*/}
                            {/*    onChange={(e: any, data:any) => {*/}
                            {/*        //e.preventDefault();*/}
                            {/*        if(moment(data.value,'DD/MM/YYYY').isAfter()){*/}
                            {/*            setAuditDateError('Final Sign off Date Invalid !')*/}
                            {/*            setState({...state, finalSignoffDate: ''})*/}
                            {/*            return;*/}
                            {/*        }else {*/}
                            {/*            setAuditDateError('')*/}
                            {/*            setState({...state, finalSignoffDate: data.value})*/}
                            {/*        }*/}

                            {/*    }}*/}
                            {/*    //onLostFocus={fetchAuditsFromDb}*/}
                            {/*    disabled={isEditCourse}*/}
                            {/*/>*/}
                        </Grid.Column>
                        <Grid.Column width={8} computer={8} tablet={8} mobile={16} largeScreen={8} widescreen={8}>

                        </Grid.Column>
                    </Grid.Row>
                    {loading ? (<>
                        <Grid.Row columns={3}>
                            <Grid.Column></Grid.Column>
                            <Grid.Column>
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#1559A3"
                                    wrapperStyle={{margin: 'auto', display: 'flex', justifyContent: 'center'}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#464646"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </Grid.Column>
                            <Grid.Column></Grid.Column>
                        </Grid.Row>
                    </>) :  (
                            <>
                                { pathName == '/audits' ?(<Grid.Row columns={1}>
                                    <Grid.Column width={16} className='button-flex' style={{display:'flex', justifyContent:'end'}}>
                                        <OutLineButton id={'btnSearch'} className={"background-transaparent"} style={{width:'150px'}} onClick={()=>{
                                            setType('active')
                                            setCurrent(0);
                                                fetchAuditsFromDb()                                          
                                        }} color={'blue'} text={'Search'}/>
                                    </Grid.Column>
                                </Grid.Row>):null}
                                {rsaAuditDet.length > 0 ? (<Grid.Row>
                                    <Grid.Column width={16}>
                                        <p style={{fontSize:'16px', fontWeight:'bold'}}>Matching audit(s) found in the Register, you could add yourself using “Add me”:</p>
                                        <hr/>
                                        { pathName == '/auditst' && !(state && state.finalSignoffDate && state.finalSignoffDate.length > 0 && state.stageOfAudit && state.stageOfAudit.length > 0 && state.stateAuditPerformed && state.stateAuditPerformed.length > 0) ?(<Grid.Row columns={1}>
                                            <Grid.Column width={16} className='button-flex' style={{display:'flex', justifyContent:'end'}}>
                                                <OutLineButton id={'btnSearch'} className={"background-transaparent"} style={{width:'150px'}} onClick={()=>{
                                                    setType('verify')
                                                    setCurrent(0);
                                                    fetchAllAuditsFromDb('verify',0);

                                                }} color={'blue'} text={'All Verified Audits'}/>
                                            </Grid.Column>
                                        </Grid.Row>):null}
                                       <div style={{overflow:'auto'}}>
                                           <RSATable
                                               head={rsaAuditorHead}
                                               body={rsaAuditDet}
                                               pageCount={pages}
                                               onChange={onChange}
                                               current={current}
                                           />
                                       </div>
                                    </Grid.Column>
                                </Grid.Row>) : null}
                                <Grid.Row>
                                    <Grid.Column>
                                        <hr/>
                                    </Grid.Column>
                                </Grid.Row>
                                {pathName != '/audits' ? (<Grid.Row columns={2}>
                                    <Grid.Column width={13}>
                                        <p style={{fontSize:'16px', fontWeight:'bold'}}>Otherwise, please continue adding the details of the new audit using “Add New Audit”.</p>
                                    </Grid.Column>
                                    <Grid.Column width={3} className='button-flex'>
                                        {session.status != 4 ? (<OutLineButton id={'btnAddNew'} style={{width:'100px'}} className={"background-transaparent"} onClick={()=>{setAddNewAudit(true); setRSADet([]); setIntLoad(false)}} color={'blue'} text={'Add New'}/> ) : null}
                                    </Grid.Column>
                                </Grid.Row>):null}
                            </>
                        )}
                    {/*<Grid.Row columns={2}>
                        <Grid.Column width={4}>
                            <span style={{display: 'inline-flex'}}><b>Support Documents</b></span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <FileUpload
                                onChange={(e: any) => {
                                    if (resumeValidation(e)) {
                                        console.log('resume validation success')
                                    }
                                }}
                                color={'blue'}/>
                        </Grid.Column>
                    </Grid.Row>*/}
                    <Grid.Row>
                        <Modal open={open ? open : false} modalAction={false} setOpen={setOpen} title={"Add Audit Details"}
                               ModalBody={() => (
                                   <AddNewAudit
                                       states={states}
                                       auditStages={auditStages}
                                       auditId={null}
                                       toggleModel={toggleModelPop}
                                       isEdit={true}
                                       auditorId={session.auditorId}
                                       setAuditor={null}
                                   />
                               )}/>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default SearchAudit