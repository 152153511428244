import React, {useEffect, useState} from "react";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";
import {Container, Grid, Table} from "semantic-ui-react";
//import {AuditorRegionValueProps, QualificationValueProps} from "../auditor/profile/DataModal";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import Position from "../../framework/types/Position";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import DropDownOptions from "../../framework/types/DropDownOptions";
import {SearchReq} from "../../models/apiRequest/Auditor/SearchReq";
import {SearchApiCall} from "./SearchController";
import {AuditorSearchResRow} from "../../models/apiResponce/Auditor/SearchRes";
import {Pagination, PaginationProps} from "antd";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import {Oval} from "react-loader-spinner";
import Modal from "../../framework/components/Modals";

interface Props {
    //eslint-disable-next-line
    states: any,
    focusMode: string,
    //eslint-disable-next-line
    setFocusMode: any,
    //eslint-disable-next-line
    setStateRegion: any;
}

const PublicSearch = (props: Props) => {
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<number>(0);
    const [searchRes, setSearchres] = useState<AuditorSearchResRow[]>([])
    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});
    const [Open, setOpen] = useState(false)

    return (
        <Container fluid={true} style={{marginTop: '14px', marginBottom: '14px'}}>
            <InnerTitleBar
                title={'Search for Auditors'}
                usreName={''}
                userRole={""}
                lastLogin={''}
                status={''}
            />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={1}>

                    </Grid.Column>
                    <Grid.Column widescreen={2} computer={2} largeScreen={2} only='computer wideScreen, largeScreen'>
                        {/*{isSearch ? (<SideSearchOptions*/}
                        {/*    focusMode={props.focusMode}*/}
                        {/*    setFocusMode={props.setFocusMode}*/}
                        {/*    setStateRegion={props.setStateRegion}*/}
                        {/*    states={props.states}*/}
                        {/*    setIsSearch={setIsSearch}*/}
                        {/*    setRecord={setRecord}*/}
                        {/*    setSearchres={setSearchres}*/}
                        {/*    setState={setState}*/}
                        {/*    stateSearch={state}*/}
                        {/*    setIsLoading={setIsLoading}*/}
                        {/*/>) : null}*/}
                    </Grid.Column>
                    <Grid.Column width={11} computer={11} tablet={11} widescreen={11} largeScreen={11} mobile={14}>
                        {isSearch ? isLoading ? (<div style={{height: '40vh', marginTop: '30vh', display:'flex', justifyContent:'center'}}>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}

                            /></div>) : (
                            <SearchResult
                                searchResults={searchRes}
                                recordCount={record}
                                setIsSearch={setIsSearch}
                                state={state}
                            />
                        ) : (
                            <SearchOptions
                                focusMode={props.focusMode}
                                setFocusMode={props.setFocusMode}
                                setStateRegion={props.setStateRegion}
                                states={props.states}
                                setIsSearch={setIsSearch}
                                setRecord={setRecord}
                                setSearchres={setSearchres}
                                setState={setState}
                                setIsLoading={setIsLoading}
                            />)}
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default PublicSearch;

interface AvailabiltyProps {
    //eslint-disable-next-line
    focusMode: any
    //eslint-disable-next-line
    setFocusMode: any
    //eslint-disable-next-line
    setStateRegion: any
    //eslint-disable-next-line
    states: any
    setIsSearch: React.Dispatch<React.SetStateAction<boolean>>
    setRecord:  React.Dispatch<React.SetStateAction<number>>,
    setSearchres:  React.Dispatch<React.SetStateAction<AuditorSearchResRow[]>>
    setState:  React.Dispatch<React.SetStateAction<SearchReq>>
    stateSearch?:SearchReq
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

// interface StateProps extends AuditorRegionValueProps, QualificationValueProps {
//     AuditorName?: string,
//     CompanyName?: string,
//     level1?: boolean,
//     level2?: boolean,
//     level3?: boolean,
//     states?: any,
//     postcode?: string,
//     proximity?: string,
//     RD?: string,
//     RSE?: string,
//     TE?: string,
//     BS?: string,
//     TTM?: string,
// }

const SearchOptions = (props: AvailabiltyProps) => {
    const [state, setState] = React.useState<SearchReq>({pageSize: 10, pageNumStartingZero: 0, countryStateId: 1});
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [nsw, setNsw] = React.useState<boolean>(false);
    const [Open, SetOpen] = useState(false)
    //eslint-disable-next-line
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const experience = [{key: 1, text: 'Any Years', value: '1'},
        {key: 2, text: 'Over 1 year', value: '2'},
        {key: 3, text: 'Over 3 years', value: '3'},
        {key: 4, text: 'Over 5 years', value: '4'},
        {key: 5, text: 'Over 10 years', value: '5'}
    ]

    useEffect(() => {
        props.setStateRegion(state);
    }, [state])

    useEffect(() => {
        Promise.resolve(props.states).then((value) => {
            setStages(value)
        })
    }, [props.states])

    const OnSearch = () => {
        const searchData = SearchApiCall(state)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                props.setRecord(value.totalRows)
                props.setSearchres(value.auditorSearchRess)
                props.setState(state)
            }
            props.setIsSearch(true);
        })

    }
    const CheckArraySet = (value: number, item?: number[]) => {
        let tempVal = item
        if (tempVal == undefined || !tempVal.includes(value)) {
            tempVal ? tempVal.push(value) : tempVal = [value]
        } else {
            if (tempVal != undefined && tempVal.includes(value)) {
                tempVal = tempVal.filter(r => r != value)
            }
        }

        return tempVal
    }
    return (
        <React.Fragment>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Search'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Auditor details</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDefault labelText={'Name'} labelPosition={Position.Top}
                                         id={'txtName'} fluid={true} type={'text'}
                                         placeholder={'Enter Name'}
                                         required={false}
                                         value={state?.name}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, name: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDefault labelText={'Company/Organisation'} labelPosition={Position.Top}
                                         id={'txtCompany'} fluid={true} type={'text'}
                                         placeholder={'Enter Company/Organisation'}
                                         required={false}
                                         value={state?.companyOrOrganisation}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, companyOrOrganisation: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                {/**######################################################################################################*/}
                <Grid.Row columns={2}>
                    <Grid.Column widescreen={13} mobile={16} tablet={8} computer={10} largeScreen={12}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Level of accreditation</b></span>
                    </Grid.Column>
                    <Grid.Column  widescreen={3} mobile={16} tablet={8} computer={6} largeScreen={4} style={{display:'flex' ,justifyContent:'end'}}>
                        <span style={{display: 'inline-flex', fontSize:'18px', cursor:'pointer'}} onClick={() => {SetOpen(true)}}><b><u>Definition of the levels</u></b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Auditor (Level 1)'} name={'level'} id={'level1'}
                                          value={state?.auditorLevels?.includes(1)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 1, state?.auditorLevels)
                                              setState({
                                                  ...state,
                                                  auditorLevels: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Senior Auditor (Level 2)'} name={'level'} id={'level2'}
                                          value={state?.auditorLevels?.includes(2)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 2, state?.auditorLevels)
                                              setState({
                                                  ...state,
                                                  auditorLevels: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Lead Auditor (Level 3)'} name={'level'} id={'level3'}
                                          value={state?.auditorLevels?.includes(3)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 3, state?.auditorLevels)
                                              setState({
                                                  ...state,
                                                  auditorLevels: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                {/*#######################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Location of auditor</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'State'}
                            labelPosition={Position.Top}
                            id={'State'}
                            placeholder={'Enter State'}
                            options={stages}
                            required={false}
                            clearable={true}
                            value={state?.auditorLocationStateId ? state?.auditorLocationStateId.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, auditorLocationStateId: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDefault labelText={'Postcode'} labelPosition={Position.Top}
                                         id={'txtPostcode'} fluid={true} type={'text'}
                                         placeholder={'Enter Postcode'}
                                         required={false}
                                         value={state?.auditorLocationPostCode}
                            //onError={fnameError}
                            //eslint-disable-next-line
                                         onChange={(e: any) => {
                                             setState({...state, auditorLocationPostCode: e.target.value});
                                         }}
                                         disabled={!isEdit}
                        />
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Proximity'}
                            labelPosition={Position.Top}
                            id={'Proximity'}
                            placeholder={'Enter Proximity'}
                            options={[{key: 1, text: 'Within 0km', value: '1'},
                                {key: 2, text: 'Within 5km', value: '2'},
                                {key: 3, text: 'Within 10km', value: '3'},
                                {key: 4, text: 'Within 20km', value: '4'},
                                {key: 5, text: 'Within 50km', value: '5'},
                                {key: 6, text: 'Within 100km', value: '6'},
                                {key: 7, text: 'Within 250km', value: '7'},
                                {key: 8, text: 'Within 500km', value: '8'},
                            ]}
                            required={false}
                            clearable={true}
                            value={state?.auditorLocationProximityInKm ? state?.auditorLocationProximityInKm.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, auditorLocationProximityInKm: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                {/*#######################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Region available to work in</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'} value={nsw}
                                          onChange={() => {
                                              setNsw(!nsw);
                                          }} disabled={!isEdit}/>
                        <Grid.Row columns={1}>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(1)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                    const checkedStat = CheckArraySet( 1, state?.regionIdsAvailableToWorkIn)
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: checkedStat
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'North'} name={'state'} id={'north'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(2)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                    const checkedStat = CheckArraySet( 2, state?.regionIdsAvailableToWorkIn)
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: checkedStat
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'South'} name={'state'} id={'south'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(3)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                    const checkedStat = CheckArraySet( 3, state?.regionIdsAvailableToWorkIn)
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: checkedStat
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: '20px'}}>
                                <NameWithCheckBox label={'West'} name={'state'} id={'west'}
                                                  value={state?.regionIdsAvailableToWorkIn?.includes(4)}
                                    //eslint-disable-next-line
                                                  onChange={(e: any, data: any) => {
                                                    const checkedStat = CheckArraySet( 4, state?.regionIdsAvailableToWorkIn)
                                                      setState({
                                                          ...state,
                                                          regionIdsAvailableToWorkIn: checkedStat
                                                      });
                                                  }} disabled={!isEdit}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(5)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 5, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(6)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 6, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(7)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 7, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(8)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 8, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(9)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 9, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(10)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 10, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(11)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 11, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'}
                                          value={state?.regionIdsAvailableToWorkIn?.includes(12)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 12, state?.regionIdsAvailableToWorkIn)
                                              setState({
                                                  ...state,
                                                  regionIdsAvailableToWorkIn: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                {/*#############################################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Relevant professional experience</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Road Design'}
                            labelPosition={Position.Left}
                            id={'RoadDesign'}
                            placeholder={'Enter Road Design'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.roadDesign ? state?.roadDesign.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, roadDesign: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Road Safety Engineering'}
                            labelPosition={Position.Left}
                            id={'RoadSafetyEngineering'}
                            placeholder={'Enter Road Safety Engineering'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.roadSafetyEngineering ? state?.roadSafetyEngineering.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, roadSafetyEngineering: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Traffic Engineering'}
                            labelPosition={Position.Left}
                            id={'TrafficEngineering'}
                            placeholder={'Enter Traffic Engineering'}
                            options={experience}
                            required={false}
                            clearable={true}
                            fluid={true}
                            value={state?.trafficEngineering ? state?.trafficEngineering.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, trafficEngineering: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Behavioural Science'}
                            labelPosition={Position.Left}
                            id={'BehaviouralScience'}
                            placeholder={'Enter Behavioural Science'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.behaviouralScience ? state?.behaviouralScience.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, behaviouralScience: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>
                        <NameWithDropDown
                            selection={true}
                            labelText={'Traffic/Transport Management'}
                            labelPosition={Position.Left}
                            id={'Traffic/TransportManagement'}
                            placeholder={'Enter Traffic/Transport Management'}
                            options={experience}
                            required={false}
                            fluid={true}
                            clearable={true}
                            value={state?.trafficTransportManagement ? state?.trafficTransportManagement.toString() : '0'}
                            //eslint-disable-next-line
                            onChange={(e: any, data: any) => {
                                e.preventDefault();
                                setState({...state, trafficTransportManagement: data.value});
                            }}
                            disabled={!isEdit}

                        />
                    </Grid.Column>
                    <Grid.Column width={8} widescreen={8} computer={8} tablet={16} mobile={16}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                {/*#############################################################################################################################*/}
                <Grid.Row>
                    <Grid.Column width={16}>
                        <span style={{display: 'inline-flex', fontSize:'20px'}}><b>Specific expertise</b></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Construction Projects'} name={'state'} id={'constructionProject'}
                                          value={state?.specificExpertiseIds?.includes(1)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 1, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Reconstruction Projects'} name={'state'} id={'reconstructionProjects'}
                                          value={state?.specificExpertiseIds?.includes(2)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 2, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Intersection Projects'} name={'state'} id={'intersectionProjects'}
                                          value={state?.specificExpertiseIds?.includes(3)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 3, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Local Area Traffic Management Projects'} name={'state'}
                                          id={'localAreaTrafficManagementProjects'}
                                          value={state?.specificExpertiseIds?.includes(4)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 4, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Traffic Signal Projects'} name={'state'} id={'trafficSignalProjects'}
                                          value={state?.specificExpertiseIds?.includes(6)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 6, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Temporary Traffic Management'} name={'state'}
                                          id={'temporaryTrafficManagement'}
                                          value={state?.specificExpertiseIds?.includes(7)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 7, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Development Planning'} name={'state'} id={'developmentPlanning'}
                                          value={state?.specificExpertiseIds?.includes(8)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 8, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Design & Construction Projects'} name={'state'}
                                          id={'designConstructionProjects'}
                                          value={state?.specificExpertiseIds?.includes(9)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 9, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Crash Investigation and Analyses'} name={'state'}
                                          id={'crashInvestigation'}
                                          value={state?.specificExpertiseIds?.includes(10)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 10, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>
                        <NameWithCheckBox label={'Vulnerable Road User (Pedestrians, cyclists, motorcyclists)'}
                                          name={'state'} id={'vulnerableRoadUser'}
                                          value={state?.specificExpertiseIds?.includes(5)}
                            //eslint-disable-next-line
                                          onChange={(e: any, data: any) => {
                                            const checkedStat = CheckArraySet( 5, state?.specificExpertiseIds)
                                              setState({
                                                  ...state,
                                                  specificExpertiseIds: checkedStat
                                              });
                                          }} disabled={!isEdit}/>
                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>

                    </Grid.Column>
                    <Grid.Column width={5} widescreen={5} computer={5} tablet={5} mobile={16}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Search'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal open={Open ? Open : false}  setOpen={SetOpen}  title={'FAQs'} ModalBody={()=>(
                <iframe src={`${window.location.protocol}//${window.location.host}/faqspop?go=levels`} style={{width:'100%', height:'80vh', borderStyle:'none'}}></iframe>
            )} />
        </React.Fragment>
    );
}

const SideSearchOptions = (props: AvailabiltyProps) => {

    const [state, setState] = React.useState<SearchReq>(props.stateSearch ? props.stateSearch : {pageSize: 10, pageNumStartingZero: 0, countryStateId: 1} );
    const [stages, setStages] = useState<DropDownOptions[]>([]);
    const [nsw, setNsw] = React.useState<boolean>(false);
    //eslint-disable-next-line
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const experience = [{key: 1, text: 'Any Years', value: '1'},
        {key: 2, text: 'Over 1 year', value: '2'},
        {key: 3, text: 'Over 3 years', value: '3'},
        {key: 4, text: 'Over 5 years', value: '4'},
        {key: 5, text: 'Over 10 years', value: '5'}
    ]

    useEffect(() => {
        props.setStateRegion(state);
    }, [state])

    useEffect(() => {
        Promise.resolve(props.states).then((value) => {
            setStages(value)
        })
    }, [props.states])
    useEffect(()=>{
        OnSearch()
    },[state])

    const OnSearch = () => {
        props.setIsLoading(true)
        const searchData = SearchApiCall(state)
        Promise.resolve(searchData).then((value) => {
            if (value) {
                props.setRecord(value.totalRows)
                props.setSearchres(value.auditorSearchRess)
                props.setState(state)
            }
            props.setIsLoading(false)
            props.setIsSearch(true);
        })

    }

    const CheckArraySet = (Checked: boolean, value: number, item?: number[]) => {
        let tempVal = item
        if (Checked) {
            tempVal ? tempVal.push(value) : tempVal = [value]
        } else {
            if (tempVal != undefined && tempVal.includes(value)) {
                tempVal = tempVal.filter(r => r != value)
            }
        }

        return tempVal
    }

    // const CheckArrayClear = (Checked: boolean, value: number, item?: number[]) => {
    //     let tempVal = item
    //     if (Checked) {
    //         tempVal ? tempVal.push(value) : tempVal = [value]
    //     } else {
    //         if (tempVal != undefined && tempVal.includes(value)) {
    //             tempVal = tempVal.filter(r => r != value)
    //         }
    //     }
    //
    //     return tempVal
    // }

    return (
        <React.Fragment>
            <Grid >
                <Grid.Row columns={2}>
                    <Grid.Column width={10}>
                        <span style={{display: 'inline-flex'}}><b>Filters</b></span>
                    </Grid.Column>
                    <Grid.Column width={6} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={()=>{
                            setNsw(false)
                            setState({
                                pageSize: 10,
                                pageNumStartingZero: 0,
                                countryStateId: 1,
                                auditorLevels:[],
                                regionIdsAvailableToWorkIn:[],
                                specificExpertiseIds:[]
                            })
                        }} color='blue' className={"background-transaparent"} text='Clear'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid style={{height: '599px', overflow: 'auto', marginTop:'35px', backgroundColor: '#F9FAFB'}}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Auditor details</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Name'} labelPosition={Position.Top}
                                             id={'txtName'} fluid={true} type={'text'}
                                             placeholder={'Enter Name'}
                                             required={false}
                                             value={state?.name}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, name: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Company/Organisation'} labelPosition={Position.Top}
                                             id={'txtCompany'} fluid={true} type={'text'}
                                             placeholder={'Enter Company/Organisation'}
                                             required={false}
                                             value={state?.companyOrOrganisation}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, companyOrOrganisation: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {/**######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Level of accreditation</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Auditor (Level 1)'} name={'level'} id={'level1'}
                                              value={state?.auditorLevels?.includes(1)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(data.checked, 1, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Senior Auditor (Level 2)'} name={'level'} id={'level2'}
                                              value={state?.auditorLevels?.includes(2)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(data.checked, 2, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Lead Auditor (Level 3)'} name={'level'} id={'level3'}
                                              value={state?.auditorLevels?.includes(3)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      auditorLevels: CheckArraySet(data.checked, 3, state?.auditorLevels)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    {/*#######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Location of auditor</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'State'}
                                labelPosition={Position.Top}
                                id={'State'}
                                placeholder={'Enter State'}
                                options={stages}
                                required={false}
                                clearable={true}
                                value={state?.auditorLocationStateId ? state?.auditorLocationStateId.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, auditorLocationStateId: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDefault labelText={'Postcode'} labelPosition={Position.Top}
                                             id={'txtPostcode'} fluid={true} type={'text'}
                                             placeholder={'Enter Postcode'}
                                             required={false}
                                             value={state?.auditorLocationPostCode}
                                //onError={fnameError}
                                //eslint-disable-next-line
                                             onChange={(e: any) => {
                                                 setState({...state, auditorLocationPostCode: e.target.value});
                                             }}
                                             disabled={!isEdit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Proximity'}
                                labelPosition={Position.Top}
                                id={'Proximity'}
                                placeholder={'Enter Proximity'}
                                options={[{key: 1, text: 'Within 0km', value: '1'},
                                    {key: 2, text: 'Within 5km', value: '2'},
                                    {key: 3, text: 'Within 10km', value: '3'},
                                    {key: 4, text: 'Within 20km', value: '4'},
                                    {key: 5, text: 'Within 50km', value: '5'},
                                    {key: 6, text: 'Within 100km', value: '6'},
                                    {key: 7, text: 'Within 250km', value: '7'},
                                    {key: 8, text: 'Within 500km', value: '8'},
                                ]}
                                required={false}
                                clearable={true}
                                value={state?.auditorLocationProximityInKm ? state?.auditorLocationProximityInKm.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, auditorLocationProximityInKm: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    {/*#######################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Region available to work in</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'New South Wales'} name={'state'} id={'nsw'} value={nsw}
                                              onChange={() => {
                                                  setNsw(!nsw);
                                              }} disabled={!isEdit}/>
                            <Grid.Row columns={1}>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'Greater Sydney'} name={'state'} id={'grate'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(1)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 1, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'North'} name={'state'} id={'north'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(2)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 2, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'South'} name={'state'} id={'south'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(3)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 3, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                                <Grid.Column style={{paddingLeft: '20px'}}>
                                    <NameWithCheckBox label={'West'} name={'state'} id={'west'}
                                                      value={state?.regionIdsAvailableToWorkIn?.includes(4)}
                                        //eslint-disable-next-line
                                                      onChange={(e: any, data: any) => {
                                                          setState({
                                                              ...state,
                                                              regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 4, state?.regionIdsAvailableToWorkIn)
                                                          });
                                                      }} disabled={!isEdit}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Victoria'} name={'state'} id={'victoria'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(5)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 5, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Western Australia'} name={'state'} id={'wa'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(6)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 6, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Australia Capital Region'} name={'state'} id={'act'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(7)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 7, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Queensland'} name={'state'} id={'qld'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(8)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 8, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'South Australia'} name={'state'} id={'sa'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(9)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 9, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Tasmania'} name={'state'} id={'tas'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(10)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 10, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Northern Territory'} name={'state'} id={'nt'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(11)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 11, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Overseas'} name={'state'} id={'os'}
                                              value={state?.regionIdsAvailableToWorkIn?.includes(12)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      regionIdsAvailableToWorkIn: CheckArraySet(data.checked, 12, state?.regionIdsAvailableToWorkIn)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    {/*#############################################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Relevant professional experience</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Road Design'}
                                labelPosition={Position.Top}
                                id={'RoadDesign'}
                                placeholder={'Enter Road Design'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.roadDesign ? state?.roadDesign.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, roadDesign: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Road Safety Engineering'}
                                labelPosition={Position.Top}
                                id={'RoadSafetyEngineering'}
                                placeholder={'Enter Road Safety Engineering'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.roadSafetyEngineering ? state?.roadSafetyEngineering.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, roadSafetyEngineering: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Traffic Engineering'}
                                labelPosition={Position.Top}
                                id={'TrafficEngineering'}
                                placeholder={'Enter Traffic Engineering'}
                                options={experience}
                                required={false}
                                clearable={true}
                                fluid={true}
                                value={state?.trafficEngineering ? state?.trafficEngineering.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, trafficEngineering: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Behavioural Science'}
                                labelPosition={Position.Top}
                                id={'BehaviouralScience'}
                                placeholder={'Enter Behavioural Science'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.behaviouralScience ? state?.behaviouralScience.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, behaviouralScience: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={16}>
                            <NameWithDropDown
                                selection={true}
                                labelText={'Traffic/Transport Management'}
                                labelPosition={Position.Top}
                                id={'Traffic/TransportManagement'}
                                placeholder={'Enter Traffic/Transport Management'}
                                options={experience}
                                required={false}
                                fluid={true}
                                clearable={true}
                                value={state?.trafficTransportManagement ? state?.trafficTransportManagement.toString() : '0'}
                                //eslint-disable-next-line
                                onChange={(e: any, data: any) => {
                                    e.preventDefault();
                                    setState({...state, trafficTransportManagement: data.value});
                                }}
                                disabled={!isEdit}

                            />
                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                    </Grid.Row>
                    {/*#############################################################################################################################*/}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <span style={{display: 'inline-flex'}}><b>Specific expertise</b></span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Construction Projects'} name={'state'} id={'constructionProject'}
                                              value={state?.specificExpertiseIds?.includes(1)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 1, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Reconstruction Projects'} name={'state'}
                                              id={'reconstructionProjects'}
                                              value={state?.specificExpertiseIds?.includes(2)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 2, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Intersection Projects'} name={'state'} id={'intersectionProjects'}
                                              value={state?.specificExpertiseIds?.includes(3)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 3, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Local Area Traffic Management Projects'} name={'state'}
                                              id={'localAreaTrafficManagementProjects'}
                                              value={state?.specificExpertiseIds?.includes(4)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 4, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Traffic Signal Projects'} name={'state'}
                                              id={'trafficSignalProjects'}
                                              value={state?.specificExpertiseIds?.includes(6)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 6, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Temporary Traffic Management'} name={'state'}
                                              id={'temporaryTrafficManagement'}
                                              value={state?.specificExpertiseIds?.includes(7)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 7, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Development Planning'} name={'state'} id={'developmentPlanning'}
                                              value={state?.specificExpertiseIds?.includes(8)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 8, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Design & Construction Projects'} name={'state'}
                                              id={'designConstructionProjects'}
                                              value={state?.specificExpertiseIds?.includes(9)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 9, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Crash Investigation and Analyses'} name={'state'}
                                              id={'crashInvestigation'}
                                              value={state?.specificExpertiseIds?.includes(10)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 10, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={16}>
                            <NameWithCheckBox label={'Vulnerable Road User (Pedestrians, cyclists, motorcyclists)'}
                                              name={'state'} id={'vulnerableRoadUser'}
                                              value={state?.specificExpertiseIds?.includes(5)}
                                //eslint-disable-next-line
                                              onChange={(e: any, data: any) => {
                                                  setState({
                                                      ...state,
                                                      specificExpertiseIds: CheckArraySet(data.checked, 5, state?.specificExpertiseIds)
                                                  });
                                              }} disabled={!isEdit}/>
                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                        <Grid.Column width={16}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid.Row></Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={16} className='button-flex' style={{justifyContent: 'end'}}>
                        <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                       className={"background-transaparent"} text='Apply'
                                       disabled={!isEdit}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}

interface SearchResultsProps {
    //eslint-disable-next-line
    setIsSearch: any,
    recordCount: number,
    //eslint-disable-next-line
    searchResults: AuditorSearchResRow[],
    state: SearchReq
}

const SearchResult = (props: SearchResultsProps) => {
    const [current, setCurrent] = useState(1);
    const [icon1, setIcon1] = useState('')
    const [icon2, setIcon2] = useState('')
    const [icon3, setIcon3] = useState('')
    const [searchCol, setSearchCol] = useState(0)
    const [searchOrder, setSearchOrder] = useState(false)
    //eslint-disable-next-line
    const [data, setData] = useState<any>([]);


    useEffect(() => {
        if (props.searchResults) {
            SetDataTable(props.searchResults)
        }

        // if(props.searchResults.length > 20){
        //     setData([{auditorName:'Chathura Priyashad',auditorCompany:'ABC Company',level:'Level 1', auditorId:'1122' }]);
        // }
    }, [props.searchResults]);

    const SetDataTable = (rawData: AuditorSearchResRow[]) => {
        //eslint-disable-next-line
        let temSearch: { auditorName: string; auditorCompany: string; level: number; auditorId: number; }[] = []
        rawData.map((r) => {
            temSearch.push({
                auditorName: r.fullName,
                auditorCompany: r.orgName,
                level: r.auditorLevel,
                auditorId: r.auditorId
            })
        })
        setData(temSearch);
    }

    useEffect(() => {
        if (data.length == 1) {
            window.location.href = `/auditor?get=${btoa(data[0].auditorId)}`
        }
    }, [data])
    const OnSearch = () => {
        props.setIsSearch(false);
    }

    const AuditorNameClick = () => {
        setIcon2('')
        setIcon3('')
        setSearchCol(2)
        if (icon1 == 'sort up') {
            setIcon1('sort down')
            OnSortClick(2, true);
            setSearchOrder(true)
        } else {
            setIcon1('sort up')
            OnSortClick(2, false);
            setSearchOrder(false)
        }
    }

    const AuditorCompanyClick = () => {
        setIcon1('')
        setIcon3('')
        setSearchCol(3)
        if (icon2 == 'sort up') {
            setIcon2('sort down')
            OnSortClick(3, true);
            setSearchOrder(true)
        } else {
            setIcon2('sort up')
            OnSortClick(3, false);
            setSearchOrder(false)
        }
    }

    const AuditorLevelClick = () => {
        setIcon2('')
        setIcon1('')
        setSearchCol(4)
        if (icon3 == 'sort up') {
            setIcon3('sort down')
            OnSortClick(4, true);
            setSearchOrder(true)
        } else {
            setIcon3('sort up')
            OnSortClick(4, false);
            setSearchOrder(false)
        }
    }

    const OnSortClick = (sortNumber: number, desc: boolean) => {
        const searchData = SearchApiCall({...props.state, orderByField: sortNumber, isOrderByDesc: desc})
        Promise.resolve(searchData).then((value) => {
            if (value) {
                SetDataTable(value.auditorSearchRess)
            }
            props.setIsSearch(true);
        })
    }

    const OnPageChange: PaginationProps['onChange'] = (Page) => {
        const searchData = SearchApiCall({
            ...props.state,
            pageNumStartingZero: Page - 1,
            isOrderByDesc: searchOrder,
            orderByField: searchCol
        })
        Promise.resolve(searchData).then((value) => {
            if (value) {
                SetDataTable(value.auditorSearchRess)
                setCurrent(Page)
            }
            props.setIsSearch(true);
        })
    }
    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                    <span
                        style={{display: 'inline-flex'}}><b>{`Search results : found ${props.recordCount - 1} results`}</b></span>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1} style={{justifyContent: 'center'}}>
                    <OutLineButton id={'btnSearchTop'} onClick={OnSearch} color='blue'
                                   className={"background-transaparent"} text='Refine Search'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <span style={{display: 'inline-flex'}}><b>Auditor details</b></span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                {data.length > 0 ? (<Grid.Column width={16} style={{Width: '1000px', overflow: 'auto'}}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorNameClick}>Auditor&apos;s name<i
                                    className={`icon ${icon1}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorCompanyClick}>Auditor&apos;s Company/Organisation <i
                                    className={`icon ${icon2}`}/></Table.HeaderCell>
                                <Table.HeaderCell style={{cursor: 'pointer', color: '#29599e'}}
                                                  onClick={AuditorLevelClick}>Level<i
                                    className={`icon ${icon3}`}/></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data.map((item: TableRowProps, index: number) => (
                                <TableRow
                                    key={index}
                                    auditorName={item.auditorName}
                                    auditorCompany={item.auditorCompany}
                                    level={item.level}
                                    auditorId={item.auditorId}
                                />
                            ))}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell colSpan='2' textAlign={'right'}>
                                    <Pagination current={current} onChange={OnPageChange} total={props.recordCount - 1}
                                                pageSize={10} showSizeChanger={false}/>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>) : (<Grid.Column width={16}>No Records found !</Grid.Column>)}
            </Grid.Row>
            <Grid.Row columns={2} style={{justifyContent: 'end'}}>
                <Grid.Column width={15} widescreen={15} mobile={16} tablet={8} computer={15} largeScreen={15}>
                </Grid.Column>
                <Grid.Column width={1} className='button-flex' widescreen={1} mobile={16} tablet={8} computer={1}
                             largeScreen={1}>
                    <OutLineButton id={'btnSearchBottom'} onClick={OnSearch} color='blue'
                                   className={"background-transaparent"} text='Refine Search'/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

interface TableRowProps {
    auditorName: string,
    auditorCompany: string,
    level: string,
    auditorId: string,
    key: number,
}

const TableRow = (props: TableRowProps) => {
    return (
        <Table.Row key={props.key}>
            <Table.Cell style={{cursor: 'pointer', color: '#29599e'}}><a
                href={`/auditor?get=${btoa(props.auditorId)}`}>{props.auditorName}</a></Table.Cell>
            <Table.Cell>{props.auditorCompany}</Table.Cell>
            <Table.Cell>{props.level}</Table.Cell>
        </Table.Row>
    );
}