import APICallMethods from "../../../framework/types/APICallMethods";
import registorModal from "../../../models/registor/registorModal";
import UserTitles from "../../../models/apiResponce/Common/userTitles";
import ApiCallAxio from "../../../framework/common/apiCallAxio";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

//to get the list of all the titles.
const fetchTitle = async () => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/usertitles`; //complete url
    const titlesArray: object[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objUserTitle: UserTitles[] = result.userTitles; //store the result in userTitles array
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objUserTitle.map((item: UserTitles) => {
        titlesArray.push({key: item.titleId, value: item.titleId, text: item.titleName})
    })
    return titlesArray;//return the array
}

//to save the registration details to the database
/**
 * title: string; // title of the user eg. Mr, Mrs, Miss, Dr, etc.
 *
 * firstName: string; // first name of the user
 *
 * middleName: string; // middle name of the user
 *
 * lastName: string; // last name of the user
 *
 * email: string; // email of the user
 */
const postRegistration = async (data: registorModal) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorRegistration/provisionalregistration`; //complete url
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    const result = await ApiCallAxio({
        url: `${url}`,
        method: APICallMethods.POST,
        data: JSON.stringify(data),
        headers: headers
    }); //call the api using ApiCall method
    console.log(result)
    return result;//return the result
}

export {fetchTitle, postRegistration};