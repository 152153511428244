import React from "react";
import {Grid, Image} from "semantic-ui-react";

import Bridge from "../../assets/images/Bridge-01.png"
import innerTitleBar from "../types/innerTitleBar";
import moment from "moment";

const InnerTitleBar = (props:innerTitleBar) => {
  return (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column width={8} computer={8} largeScreen={8} widescreen={8} tablet={16} mobile={16}   only='widescreen computer largeScreen' >
                <Image src={Bridge} className='inner-page-image'/>
                {props.usreName.length > 1 ? (<div className={'inner-page-title-section'} ><span className='inner-titlebar-username' >{`${props.usreName} `}</span>
                    {props.userRole.length > 1 ? (<span className='inner-titlebar-role' style={{marginTop:'20px'}} >{props.certExpDate ? (<span className='inner-titlebar-certexp'>{props.userRole} Level {props.level} | AED - {moment(props.certExpDate).format('DD/MM/YYYY')}</span>) : null} ({props.status})</span>) : <p className='inner-titlebar-role'>{props.AED ? (<><span className='inner-titlebar-certexp'>Accreditation Level: Auditor (Level {props.level}) </span><p className='inner-titlebar-lastlogged'> Accreditation Expiry Date: {moment(props.certExpDate).format('DD/MM/YYYY')}</p></>) : <p className='inner-titlebar-role'>&nbsp;</p>}</p> }
                    {props.lastLogin.length > 1 ? (<span className='inner-titlebar-lastlogged'><span>{`${props.newId ? props.newId : ''}`}</span><span>&nbsp;Last Logged on : {props.lastLogin}</span></span>) : null}

                </div>) : null}

            </Grid.Column>
            <Grid.Column width={8} computer={8} largeScreen={8} widescreen={8} tablet={16} mobile={16}>
                {props.title.length > 1 ? (<div className='inner-page-trapezoid'>
                </div>) : null }
                {props.title.length > 1 ? (<span className='inner-page-title'>{props.title}</span>) : null }
            </Grid.Column>
        </Grid.Row>
    </Grid>
  );
};

export default InnerTitleBar;


// return (
//     <Grid>
//         <Image src={Bridge} className='inner-page-image'/>
//         {props.title.length > 1 ? (<div className='inner-page-trapezoid'>
//         </div>) : null }
//         {props.title.length > 1 ? (<span className='inner-page-title'>{props.title}</span>) : null }
//         {props.usreName.length > 1 ? (<Grid.Row className={'inner-page-title-section'} columns={2}><Grid.Column className='inner-titlebar-username' mobile={16} computer={6} tablet={6} widescreen={6} largeScreen={6}>{`${props.usreName} ${props.newId ? ' | ' + props.newId : ''}`}</Grid.Column>{props.userRole.length > 1 ? (<Grid.Column className='inner-titlebar-role' style={{marginTop:'20px'}} mobile={16} computer={6} tablet={6} widescreen={6} largeScreen={6}>Role : {props.userRole} ({props.status})</Grid.Column>) : <p className='inner-titlebar-role'>&nbsp;</p> }</Grid.Row>) : null}
//         {props.AED ? (<span className='inner-titlebar-lastlogged'>Accreditation Level: Auditor (Level {props.level}) | Accreditation Expiry Date: {moment(props.certExpDate).format('DD/MM/YYYY')} </span>) : null}
//         {props.certExpDate ? (<span className='inner-titlebar-certexp'>Level {props.level} | AED - {moment(props.certExpDate).format('DD/MM/YYYY')}</span>) : null}
//         {props.lastLogin.length > 1 ? (<Grid.Row className='inner-titlebar-lastlogged'><Grid.Column>Last Logged on : {props.lastLogin}</Grid.Column></Grid.Row>) : null}
//     </Grid>
// );