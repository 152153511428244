import React, {useEffect} from 'react';
import {Container, Grid, Image} from "semantic-ui-react";
import InternalBG from '../../../assets/images/backgrounds/internal-pages.jpg';
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithCheckBox from "../../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import registorModal from "../../../models/registor/registorModal";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {toast} from "react-toastify";
import {postRegistration} from "./RegistrationContoller";
import Modal from "../../../framework/components/Modals";
import {emailValidator} from "../../../framework/validators/textFeildValidators";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";

interface Props {
    //eslint-disable-next-line
    title: any;
}

const PersonalDetails = (props: Props) => {
    const url = `${location.protocol}//${location.host}/registration`;
    const [title, setTitle] = React.useState<DropDownOptions[]>([]);
    const [state, setState] = React.useState<registorModal>({
        titleId: '0',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        countryStateName: 'NSW',
        fullUrlOfVerifyingAuthCodeForRegi: url
    });

    const [chkRsa, setChkRsa] = React.useState<boolean>(false);
    const [chkSa, setChkSa] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(true)


    const [disableSave, setDisableSave] = React.useState(false);

    //Error Validation
    const [confirmEmail, setConfirmEmail] = React.useState<string>('')
    const [Error, setError] = React.useState('')
    const [TError, setTError] = React.useState('')
    const [CError, setCError] = React.useState('')
    const [fnameError, setFnameError] = React.useState('')
    const [lnameError, setLnameError] = React.useState('')
    const [mnameError] = React.useState('')

    useEffect(() => {
       /* if (!chkRsa || !chkSa) {
            setDisableSave(true)
        } else {
            setDisableSave(false)
        }*/
        DisableValidation()
        setState({...state, countryStateName: 'NSW'})
        if (state?.middleName === '') {
            setState({...state, middleName: ''})
        }

    }, [chkRsa, chkSa])

    useEffect(()=>{
        //eslint-disable-next-line
        //debugger
        DisableValidation()

    },[state, confirmEmail])

    const DisableValidation = () =>{
        if( state.titleId === '' || state.titleId === '0' || state.titleId === undefined ||
            state.firstName === '' || state.firstName === undefined ||
            state.lastName === '' || state.lastName === undefined ||
            state.email === '' || state.email === undefined ||
            CError != '' || confirmEmail === '' || !chkRsa || !chkSa
        ){
            setDisableSave(true)
        }else{
            setDisableSave(false)
        }
    }


    useEffect(() => {
        Promise.resolve(props.title).then((value) => {
            console.log(value)
            setTitle(value)
        })
    }, [props.title])

    const handleSave = () => {
        if (state === undefined) {
            setError('Please enter your email address')
            setFnameError('Please enter your first name')
            setLnameError('Please enter your last name')
            //setMnameError('Please enter your middle name')
            return
        } else {
            if (state.titleId === '0' || state.titleId === undefined) {
                setTError('Please select title')
                return
            } else {
                setTError('')
            }
            if (state.firstName === '' || state.firstName === undefined) {
                setFnameError('Please enter your first name')
                return
            } else {
                setFnameError('')
            }
            if (state.lastName === '' || state.lastName === undefined) {
                setLnameError('Please enter your last name')
                return
            } else {
                setLnameError('')
            }
            if (state.email === '' || state.email === undefined ) {
                setError('Please enter your email address')
                return
            } else {
                if(!emailValidator( state?.email)){
                    setError('Please enter a valid email address');
                }else{
                    setError('');
                }
            }

            if (Error != '' && fnameError != '' && lnameError != '' && TError != '') {
                return;
            }
            if (Error === '' && fnameError === '' && lnameError === '' && TError === '') {
                const saveResult = postRegistration(state)
                //PromiseToast('Pending...', 'Save Success !', 'Error', saveResult)
                toast.dismiss()
                const id = toast.loading('Pending..');
                Promise.resolve(saveResult).then((value) => {
                    //eslint-disable-next-line
                    //debugger
                    if (value.response != undefined && value.response.data.error) {
                        toast.update(id, {render: value.response.data.error.detail, type: "error", isLoading: false});
                        //ErrorToast(value.error.detail);
                    } else {
                        toast.update(id, {render: "Success", type: "success", isLoading: false});
                    }

                    const data = value.provisionalAuditorRegistrationRes;
                    console.log(data.redirectUrl)
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    }
                })
            }

        }
        //state == undefined ? setError('Email is required') : setError('')
    }

    useEffect(() => {
        if(state?.email != undefined && state?.email?.length > 0){
            matchEmail();
        }

    }, [state?.email])

    useEffect(() => {
        if(state?.email != undefined && state?.email?.length > 0){
            matchEmail();
        }
    }, [confirmEmail])

    const matchEmail = () => {
        //eslint-disable-next-line
        //debugger;
        if(state?.email != undefined){
            if(!emailValidator( state?.email)){
                setError('Please enter a valid email address');
            }else{
                setError('');
            }
        }
        if (!state || confirmEmail == '' && state?.email == '') {
            setCError('')
            return
        }
        if (state?.email != confirmEmail) {
            setCError('Email is not match')
        } else {
            setCError('')
        }
    }

    return (
        <React.Fragment>
            <Container fluid={true} style={{ marginTop: '14px', marginBottom: '20px'}}>
                {/*<Image src={InternalBG} fluid={true}/>*/}
                <InnerTitleBar
                    title={'New Registration'}
                    usreName={""}
                    userRole={""}
                    lastLogin={""}
                    status={""}
                />
                <Grid >
                    <Grid.Row columns={3}>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            <p style={{color: "#CD123D", fontStyle: 'italic'}}>Please note that the minimum accreditation for a Road Safety Auditor on the TfNSW Register of Road Safety Auditors is level 1. <br/>To meet the minimum accreditation, you are required to have completed at least one TfNSW recognised Road Safety Audit training course and at least one road safety audit that meets either Austroads or RMS Guidelines. Check <a href='/faqs'>FAQ here</a>.</p>
                            <h3>Personal details:</h3>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithDropDown clearable={true} selection={true} labelText={'Title'}
                                                          labelPosition={Position.Top} id={'ddTitle'}
                                                          placeholder={'Select Title'}
                                                          required={true}
                                                          options={title}
                                                          value={state?.titleId}
                                                          onerror={TError}
                                            //eslint-disable-next-line
                                                          onChange={(e: any, data: any) => {
                                                              setState({...state, titleId: data.value})
                                                          }}/>
                                    </Grid.Column>
                                    <Grid.Column  width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithDefault labelText={'First name'} labelPosition={Position.Top}
                                                         id={'txtFName'} fluid={true} type={'text'}
                                                         placeholder={'Enter First Name'}
                                                         required={true}
                                                         value={state?.firstName}
                                                         onError={fnameError}
                                            //eslint-disable-next-line
                                                         onChange={(e: any) => {
                                                             setState({...state, firstName: e.target.value})
                                                         }}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithDefault labelText={'Middle initial'} labelPosition={Position.Top}
                                                         id={'txtMName'} fluid={true} type={'text'}
                                                         placeholder={'Enter Middle Initial'}
                                                         required={false}
                                                         value={state?.middleName}
                                                         onError={mnameError}
                                            //eslint-disable-next-line
                                                         onChange={(e: any) => {
                                                             setState({
                                                                 ...state,
                                                                 middleName: e.target.value !== '' ? e.target.value : ''
                                                             })
                                                         }}/>
                                    </Grid.Column>
                                    <Grid.Column width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithDefault labelText={'Last name'} labelPosition={Position.Top}
                                                         id={'txtLName'} fluid={true} type={'text'}
                                                         placeholder={'Enter Last Name'}
                                                         required={true}
                                                         value={state?.lastName}
                                                         onError={lnameError}
                                            //eslint-disable-next-line
                                                         onChange={(e: any) => {
                                                             setState({...state, lastName: e.target.value})
                                                         }}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithEmail labelText={'Email address'} labelPosition={Position.Top}
                                                       id={'txtEmail'} fluid={true} type={'text'}
                                                       placeholder={'Enter Email Address'}
                                                       required={true}
                                                       value={state?.email}
                                                       onError={Error}
                                                        //eslint-disable-next-line
                                                       onChange={(e: any) => {
                                                           setState({...state, email: e.target.value})
                                                       }}
                                                       onLostFocus={matchEmail}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8} computer={8} widescreen={8} largeScreen={8} tablet={16} mobile={16}>
                                        <NameWithEmail labelText={'Confirm email address'} labelPosition={Position.Top}
                                                       id={'txtCEmail'} fluid={true} type={'text'}
                                                       placeholder={'Confirm Email Address'}
                                                       required={true}
                                                       onError={CError}
                                                       value={confirmEmail}
                                                        //eslint-disable-next-line
                                                       onChange={(e: any) => {
                                                           setConfirmEmail(e.target.value)
                                                       }}
                                                       onLostFocus={matchEmail}
                                                       onPaste={(e:any)=>{e.preventDefault()}}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <h3>Declaration:</h3>
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <label className="checkbox-container">I confirm that I have completed at least one TfNSW recognised Road Safety Audit <span className={'reg_links'} style={{display: 'inline-flex'}}><a href={'/faqs?go=accept_courses'} target={'_blank'} rel="noreferrer">&nbsp;Training course</a><p
                                            style={{color: 'red'}}>{'*'}</p></span>
                                            <input id={'chkRSATC'} type={'checkbox'} checked={chkSa} onChange={() => {
                                                setChkSa(!chkSa)
                                            }}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label className="checkbox-container">I confirm that I have completed at least one Road safety Audit that meets either<span className={'reg_links'} style={{display: 'inline-flex'}}><a href={'https://austroads.com.au/publications/road-safety/agrs06'} target={'_blank'} rel="noreferrer">&nbsp;Austroads or RMS Guidelines</a><p
                                            style={{color: 'red'}}>{'*'}</p></span>
                                            <input id={'chkRSA'} type={'checkbox'} checked={chkRsa} onChange={() => {
                                                setChkRsa(!chkRsa)
                                            }}/>
                                                <span className="checkmark"></span>
                                        </label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>

                                </Grid.Row>
                            </Grid>
                            <p>After you&apos;ve provided an email address to use for your new account, you&apos;ll
                                receive an email asking you to verify your email address. You must click on the link in
                                this verification email to confirm your email address.</p>
                            <p>If you haven&apos;t received your verification email, it may be in the Bulk/Spam folder
                                of your email account, so please check there before requesting a replacement.</p>

                            <hr/>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column width={12}  computer={12} widescreen={12} largeScreen={12} tablet={8} mobile={4}>&nbsp;</Grid.Column>
                                    <Grid.Column width={4} computer={4} widescreen={4} largeScreen={4} tablet={8} mobile={12} className={'button-flex'}>
                                        <OutLineButton id={'btnCancel'} onClick={() => {
                                            window.location.href = '/'
                                        }} style={{width: '100%'}} color={'red'} className={"background-transaparent-red"} text={'Cancel'}/>
                                        <OutLineButton id={'btnSave'} disabled={disableSave} onClick={handleSave}
                                                       style={{width: '100%'}} color={'blue'} className={"background-transaparent"} text={'Register'}/>
                                    </Grid.Column>
                                    <Grid.Column width={2} computer={2} widescreen={2} largeScreen={2} tablet={4} mobile={6} className={'button-flex'}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={2}>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            <span><em style={{color:'red'}}>* </em><em>compulsory fields</em></span>
                        </Grid.Column>
                        <Grid.Column width={2}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Modal open={open ? open : false} title={""} ModalBody={() => (<Disclaimer handleSave={() => {
                    setOpen(false)
                }}/>)}/>
            </Container>
        </React.Fragment>
    );
}

export default PersonalDetails;

interface DisclaimerProps {
    //eslint-disable-next-line
    handleSave: any
}

const Disclaimer = (props: DisclaimerProps) => {
    return (
        <React.Fragment>
            <Grid centered>
                <Grid.Row centered>
                    <Grid.Column >
                        <h1>Disclaimer</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>As Road Safety Auditors, we use our knowledge and skills for the benefit of the community to
                            assist with delivering safer roads. In doing so, we strive to serve the community ahead of
                            personal, commercial or other interests. By registering to become a verified Road Safety
                            Auditor, you agree to commit to practise in promoting and implementing Safe Systems at every
                            opportunity for the welfare of the community. As a registered Road Safety Auditor, you will
                            commit to practise in accordance with a code of ethics reflecting the expectations from your
                            employer, industry or field. By agreeing to continue on the Register of Road Safety
                            Auditors, you accept that you will be held accountable for your conduct under the <a href={'https://roadsafetyregister.com.au//BusinessEthicsPopup.aspx'} target={'blank'} style={{color:'#29599e', fontWeight:'bold'}}>Transport
                                Statement of Business Ethics.</a></p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={5} mobile={1} computer={5} widescreen={5} largeScreen={5} tablet={2}></Grid.Column>
                    <Grid.Column className={'button-flex'} width={6} mobile={14} computer={6} widescreen={6} largeScreen={6} tablet={12}>
                        <OutLineButton id={'btnSave'} onClick={props.handleSave} style={{width: '100%'}} color={'blue'}
                                       text={'Accept to Continue'} className={"background-transaparent"}/>
                    </Grid.Column>
                    <Grid.Column width={5} mobile={1} computer={5} widescreen={5} largeScreen={5} tablet={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    );
}