import React from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/theme.scss";
import {Container, Grid} from "semantic-ui-react";

const AboutUsText = () => (
    <Grid>
        <Grid.Row>
            <Grid.Column>
                <p style={{fontSize: '18px'}}>The NSW Centre for Road Safety maintains a professional Register of Road Safety Auditors to verify auditors&apos; performance credentials and promote the professional development of auditors.</p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <p style={{fontSize: '18px'}}>The Register of Road Safety Auditors commenced in 2002. </p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <p style={{fontSize: '18px'}}>The register is currently administered by Managed Training Services. For details refer to the <Link to="/contactus" style={{cursor:'pointer',color:'#29599e', fontWeight:'bold'}} >Contact us</Link> page.</p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>

        </Grid.Row>
        <Grid.Row>

        </Grid.Row>
        <Grid.Row columns={2}>
            <Grid.Column width={8} mobile={16} computer={8} widescreen={8} largeScreen={8} tablet={16}>
                <AboutUsChild
                    title={'Transport for New South wales'}
                    urlText={'https://www.transport.nsw.gov.au/aboutus'}
                    url={'https://www.transport.nsw.gov.au/about-us'}
                />
            </Grid.Column>
            <Grid.Column width={8} mobile={16} computer={8} widescreen={8} largeScreen={8} tablet={16}>
                <AboutUsChild
                    title={'New South Wales Centre for Road Safety'}
                    urlText={'http://www.rta.nsw.gov.au/roadsafety/index.html'}
                    url={'https://roadsafety.transport.nsw.gov.au/'}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>

        </Grid.Row>
        <Grid.Row>

        </Grid.Row>
    </Grid>
);

export default AboutUsText;


const AboutUsChild = (props:any) =>(
        <Container>
            <div className={'about-us-sub'}>
                <h2>{props.title}</h2>
            </div>
            <div className={'about-us-sub-url'}>
                <a href={props.url} style={{cursor:'pointer',color:'#29599e', fontWeight:'bold'}} target={'blank'}>{props.urlText}</a>
            </div>
        </Container>
    );
